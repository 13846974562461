import React, { useState, forwardRef } from 'react';
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import People from '@mui/icons-material/People';
import PermMedia from '@mui/icons-material/PermMedia';
import Dns from '@mui/icons-material/Dns';
import Public from '@mui/icons-material/Public';

const data = [
  { icon: <People />, label: 'Authentication' },
  { icon: <Dns />, label: 'Database' },
  { icon: <PermMedia />, label: 'Storage' },
  { icon: <Public />, label: 'Hosting' },
];

interface Props {
  title: string;
  options: Array<{}>;
  subOptions?: Array<{}>;
}

const SettingList = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

interface ItemProps {
  title: string;
  description?: string;
  options?: Array<{}>;
  path?: string;
}

const CustomListItem = ({
  title = '',
  description = '',
  options = [],
  path = '',
}: ItemProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  let listItemProps = {
    component: forwardRef((props: any, ref) => (
      <Link ref={ref} {...props} to={`/${path}`} />
    )),
  };

  return (
    <>
      <ListItemButton
        alignItems="flex-start"
        component={options.length > 0 ? 'button' : listItemProps.component}
        onClick={handleClick}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontSize: 15,
            lineHeight: '20px',
          }}
          secondary={description}
          secondaryTypographyProps={{
            noWrap: true,
            fontSize: 12,
            lineHeight: '16px',
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: options.length ? 1 : 0,
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }}
        />
      </ListItemButton>
      {open &&
        options.length > 0 &&
        options.map((item: any) => (
          <ListItemButton
            key={item.title}
            sx={{ py: 0, minHeight: 32 }}
            component="a"
            href={`#/${item.path}`}
          >
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
            />
          </ListItemButton>
        ))}
    </>
  );
};

const SettingsGroupList = ({ title = '', options = [] }: Props) => {
  return (
    <Box py={2} width={200}>
      <Typography variant="body2" fontWeight="bold">
        {title}
      </Typography>
      <SettingList disablePadding>
        {options.map((item: any) => (
          <CustomListItem
            title={item.title}
            description={item.description}
            path={item.path}
            options={item.options}
            key={`nav-item-${item.title}`}
          />
        ))}
      </SettingList>
    </Box>
  );
};

export default SettingsGroupList;
