import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getSession } from '../../../redux/features/dashboard/appSlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { toggleMenu } from '../../../redux/features/dashboard/layoutSlice';
import { drawerWidth } from '../../../utils/constants';
import SettingSidebar from '../setting-layout/SettingSidebar';

import Snackbar from '../../Snackbar';

import Header from '../header';
import Sidebar from '../sidebar';
import Loader from '../../loader';

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<any>(({ theme, open }) => ({
  paddingLeft: '10px',
  paddingTop: '10px',
  paddingRight: '10px',
  minHeight: 'calc(100vh - 60px)',
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: '60px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 30),
      width: 'calc(100% - 20px)',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      padding: '16px',
      marginRight: '10px',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: '60px',
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: '100%',
    },
  }),
}));

interface Props {
  isSettingLayout?: boolean;
}

const DashboardLayout = ({ isSettingLayout = false }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [token, setToken] = useState('');
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useAppSelector((state) => state.layout.opened);
  const snackbarAlert = useAppSelector((state) => state.alert.snackbarAlert);
  const dispatch = useAppDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch(toggleMenu(!leftDrawerOpened));
  };

  useEffect(() => {
    if (!matchDownMd) {
      dispatch(toggleMenu(true));
    }
  }, [matchDownMd]);

  useEffect(() => {
    const token = localStorage.getItem('authToken') || '';
    if (!token) {
      navigate('/');
      return;
    }

    dispatch(getSession());
    setToken(token);
    setMounted(true);
  }, []);

  return mounted && token ? (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* @ts-ignore */}
      <Snackbar {...snackbarAlert} />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.paper,
          transition: leftDrawerOpened
            ? theme.transitions.create('width')
            : 'none',
        }}
      >
        <Toolbar data-component-name="DashboardHeader">
          {/* @ts-ignore */}
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>
      {/* drawer */}
      {isSettingLayout ? (
        <SettingSidebar
          drawerOpen={leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />
      ) : (
        <Sidebar
          drawerOpen={leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />
      )}
      {/* Toast Container */}
      <ToastContainer />
      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        <Outlet />
      </Main>
    </Box>
  ) : (
    <Loader />
  );
};

export default DashboardLayout;
