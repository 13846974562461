import React from 'react';

import { Formik } from 'formik';

import ChannelForm from './ChannelForm';

interface Props {
  onAddClient: Function;
  onEditClient: Function;
  initialValues: any;
}

export default (props: Props) => {
  const {
    id = '',
    name = '',
    website = '',
    linkedinURL = '',
    companyAddress = '',
    description = '',
    spocs = [],
  } = props.initialValues;
  console.log('props', id);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name,
        website,
        linkedinURL,
        companyAddress: companyAddress,
        description,
        spoc: spocs,
      }}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Company name required!';
        }
        if (!values.website) {
          errors.website = 'Website is required!';
        }
        if (!values.linkedinURL) {
          errors.linkedinURL = 'LinkeDin URL required!';
        }
        if (!values.companyAddress) {
          errors.companyAddress = 'Please add company address!';
        }
        if (!values.description) {
          errors.description =
            'Some brief description of the company required!';
        }

        values.spoc.forEach((spoc: any, index: any) => {
          Object.keys(spoc).map((key) => {
            if (!spoc[key]) {
              errors['spoc'][index][key] = `${key} is required`;
            }
          });
        });

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(() => {
          setSubmitting(false);
        }, 3000);
        if (id) {
          props.onEditClient(values, Number(id));
        } else {
          props.onAddClient(values);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <ChannelForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  );
};
