import { useState, useMemo, useCallback, useRef } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import { Document, Page, pdfjs } from 'react-pdf';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

import { ROOT_URL } from '../../utils/constants';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

interface Props {
  cvUrl: string | null;
  token: string;
  loading: boolean;
  onChangeCV?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Resume = ({ cvUrl, onChangeCV, loading }: Props) => {
  const [numPages, setNumPages] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const authToken = useMemo(() => localStorage.getItem('authToken'), []);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    []
  );

  const memoizedOptions = useMemo(
    () => ({
      httpHeaders: {
        authorization: `Bearer ${authToken}`,
      },
    }),
    [authToken]
  );

  const handleUploadFileClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const Row = useCallback(
    ({ index, style }: ListChildComponentProps) => (
      <div style={style}>
        <Page
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          width={600}
          key={`page-${index + 1}`}
        />
      </div>
    ),
    []
  );

  // Memoize the condition to avoid unnecessary re-renders
  const renderContent = useMemo(() => {
    if (loading) {
      return <CircularProgress size={24} />;
    }

    if (!cvUrl) {
      return (
        <Typography>No Resume has been found for this candidate!</Typography>
      );
    }

    return (
      <Document
        file={`${ROOT_URL}/api/v1/job-application/view?file=${cvUrl}`}
        onLoadSuccess={onDocumentLoadSuccess}
        options={memoizedOptions}
        error="Failed to load file"
        loading="Loading..."
      >
        {numPages > 0 && (
          <List height={600} itemCount={numPages} itemSize={900} width="100%">
            {Row}
          </List>
        )}
      </Document>
    );
  }, [
    loading,
    cvUrl,
    numPages,
    ROOT_URL,
    memoizedOptions,
    onDocumentLoadSuccess,
    Row,
  ]);

  return (
    <Card>
      <CardHeader
        title="Resume"
        sx={{ pb: 1 }}
        subheader="Candidate uploaded resume document"
        action={
          <>
            <input
              type="file"
              ref={fileInputRef}
              accept=".doc,.docx,.pdf"
              style={{ display: 'none' }}
              onChange={onChangeCV}
            />
            <Tooltip title="Change Resume">
              <IconButton color="primary" onClick={handleUploadFileClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            {cvUrl && (
              <Tooltip title="Download Resume">
                <a href={cvUrl} target="_blank" rel="noopener noreferrer">
                  <IconButton color="primary">
                    <DownloadIcon />
                  </IconButton>
                </a>
              </Tooltip>
            )}
          </>
        }
      />

      <CardContent>{renderContent}</CardContent>
    </Card>
  );
};

export default Resume;
