import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  fetchWorkspaceClients,
  addClient as addClientService,
  updateClient as updateClientService,
} from '../../services/client';
import { RootState } from '../../store';
import { showSnackbar } from './alertSlice';
import { logout } from '../onboarding/loginSlice';

export interface IClient {
  id: number;
  name: string;
  workspaceId?: number;
}

export interface IClientState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
  clients: Array<IClient>;
  selectedClient?: IClient;
}

const initialState: IClientState = {
  loading: 'idle',
  error: '',
  clients: [],
};

export const getClients = createAsyncThunk(
  'dashboard/clients',
  async (_args, { getState }) => {
    const state = getState() as RootState;
    //@ts-ignore
    const currentWorkspace = state.app.workspace.slug;
    const response = await fetchWorkspaceClients(currentWorkspace);
    return response.data;
  }
);

export const addClient = createAsyncThunk(
  'dashboard/addClients',
  async (data: object, { getState, dispatch, rejectWithValue }) => {
    try {
      const state: any = getState();
      const currentWorkspace = state.app.workspace;
      const response = await addClientService({
        ...data,
        workspaceId: currentWorkspace.id,
      });
      dispatch(getClients());
      return response.data;
    } catch (error) {
      //@ts-ignore
      const errorMessage =
        //@ts-ignore
        error?.response?.data?.message ||
        'Something went wrong! Please try again!';
      dispatch(
        showSnackbar({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      );
      //@ts-ignore
      if (!error.response) {
        throw error;
      }
      //@ts-ignore
      rejectWithValue(error.response.data);
    }
  }
);

export const updateClient = createAsyncThunk(
  'dashboard/updateClients',
  async (payload: any, { getState, dispatch, rejectWithValue }) => {
    try {
      const state: any = getState();
      const currentWorkspace = state.app.workspace;
      await updateClientService(
        {
          ...payload.data,
          workspaceId: currentWorkspace.id,
        },
        payload.channelId
      );
      dispatch(getClients());
      return;
    } catch (error) {
      //@ts-ignore
      const errorMessage =
        //@ts-ignore
        error?.response?.data?.message ||
        'Something went wrong! Please try again!';
      dispatch(
        showSnackbar({
          open: true,
          severity: 'error',
          message: errorMessage,
        })
      );
      //@ts-ignore
      if (!error.response) {
        throw error;
      }
      //@ts-ignore
      rejectWithValue(error.response.data);
    }
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    selectClient: (state, action) => {
      state.selectedClient = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(getClients.fulfilled, (state, action) => {
        return {
          ...state,
          clients: action.payload,
          loading: 'succeeded',
        };
      })
      .addCase(getClients.rejected, (state, action) => {
        return {
          ...state,
          loading: 'failed',
        };
      })
      .addCase(addClient.pending, (state) => {
        return {
          ...state,
          loading: 'pending',
        };
      })
      .addCase(addClient.fulfilled, (state) => {
        return {
          ...state,
          loading: 'succeeded',
        };
      })
      .addCase(addClient.rejected, (state) => {
        return {
          ...state,
          loading: 'failed',
        };
      })
      .addCase(logout, (state) => {
        state.loading = 'idle';
        state.error = '';
        state.clients = [];
        state.selectedClient = {
          id: 0,
          name: '',
        };
      });
  },
});

export const { selectClient } = clientSlice.actions;

export default clientSlice.reducer;

export const getUserWorkspaceClients = (state: RootState) => {
  const clients = state.client.clients || [];
  const sortedClients = [...clients].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return sortedClients;
};

export const getClientOptions = (state: RootState) => {
  const { loading, clients } = state.client;
  if (loading === 'succeeded') {
    return clients.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  }
  return [];
};
