import { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';

import Comment from './Comment.react';
import InputField from './InputField';

interface Props {
  comments: Array<any>;
  user: any;
  onSubmit: Function;
  isSubmitting?: boolean;
  stakeholder?: Array<{ id: number; name: string; email: string }>;
}

// TODO: Rich text editor and Emoji input changes
// TODO: Optimization of large data set

const CommentView = (props: Props) => {
  const {
    comments = [],
    user = {},
    onSubmit,
    isSubmitting = false,
    stakeholder = [],
  } = props;

  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle2" pb={1}>
          Recent Activity
        </Typography>

        <InputField
          mode="COMMENT"
          user={{
            name: user.name,
            id: user.id,
            profilePicUrl: user.profilePic,
          }}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          mentionUsers={stakeholder}
        />
        <Box pt={2} mt={3} pb={1} ml={'-5px'} borderTop="1px solid #edeff1">
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : (
            comments.map((comment) => (
              <Comment
                key={`comment-view-${comment.id}`}
                comment={comment}
                user={user}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                mentionUsers={stakeholder}
              />
            ))
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CommentView;
