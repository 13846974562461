import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { SettingAPI } from '../../services/setting.service';

type ILoading = 'idle' | 'pending' | 'succeeded' | 'failed';

interface IState {
  emailProviders: {
    list: Array<any>;
    loading: ILoading;
  };
}

const initialState: IState = {
  emailProviders: {
    loading: 'idle',
    list: [],
  },
};

export const getProviders = createAsyncThunk(
  'emailer-integration/getProviders',
  async (_, { getState }) => {
    const { app: { workspace: { id = '' } = {} } = {} }: any =
      getState() as RootState;
    const response = await SettingAPI.getProviders(id);
    return response.data;
  }
);

export const setUpEmailProviderConfig = createAsyncThunk(
  'emailer-integration/setUpEmailProviderConfig',
  async (_, payload: any) => {
    const response = await SettingAPI.setUpEmailProviderConfig(payload);
    return response;
  }
);

export const updateEmailProviderConfig = createAsyncThunk(
  'emailer-integration/updateEmailProviderConfig',
  async (_, payload: any) => {
    const response = await SettingAPI.updateEmailProviderConfig(payload);
    return response;
  }
);

export const enableEmailProvider = createAsyncThunk(
  'emailer-integration/emailProviderEnableFlag',
  async (_, payload: any) => {
    const response = await SettingAPI.emailProviderEnableFlag(
      payload.id,
      payload.data
    );
    return response;
  }
);

export const deleteEmailProvider = createAsyncThunk(
  'emailer-integration/deleteEmailProvider',
  async (_, payload: any) => {
    const response = await SettingAPI.deleteEmailProvider(payload.id);
    return response;
  }
);

const integrationSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    updateProvidersList: (state, action) => {
      state.emailProviders.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviders.rejected, (state) => {
        state.emailProviders.loading = 'failed';
      })
      .addCase(getProviders.pending, (state) => {
        state.emailProviders.loading = 'pending';
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.emailProviders.list = action.payload;
        state.emailProviders.loading = 'succeeded';
      });
  },
});

export const { updateProvidersList } = integrationSlice.actions;

export default integrationSlice.reducer;
