import { useRoutes } from 'react-router-dom';

import AuthenticationRoutes from './AuthenticationRoutes';
import LandingRoutes from './LandingRoutes';
import WorkspaceRoutes from './WorkspaceRoutes';
import ServerAckRoutes from './ServerAckRoutes';
import SettingsRoutes from './SettingsRoutes';

export default function Routes() {
  return useRoutes([
    LandingRoutes,
    AuthenticationRoutes,
    ServerAckRoutes,
    WorkspaceRoutes,
    SettingsRoutes,
  ]);
}
