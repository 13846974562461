import { lazy } from 'react';

import DashboardLayout from '../components/layout/dashboard-layout';
import DashboardPage from '../pages/Dashboard.page';
import SuspenseView from '../components/SuspenseView.react';

//TODO: view component should be moved to view folder
import AddCandidate from '../components/AddCandidate';
import JobApplicants from '../components/JobApplicants';
import CandidateDetailsView from '../components/candidate-details';
import Invite from '../components/Invite';
import Users from '../components/workspace-users';
import EmailComposer from '../components/email-composer';
import EventCalendar from '../components/event-calendar';
// End of TODO

const TalentPoolPage = lazy(() => import('../pages/talent-pool'));
const Reports = lazy(() => import('../pages/reports/Report.page'));
const JobPostingPage = lazy(() => import('../pages/JobPosting.page'));
const EditJobPage = lazy(() => import('../pages/EditJob.page'));
const SettingsPage = lazy(() => import('../pages/settings/Settings.page'));
const NotificationPage = lazy(() => import('../pages/Notification.page.react'));

const AddJob = lazy(() => import('../pages/AddJob.page'));
const RecruiterInboxPage = lazy(() => import('../pages/RecruiterInbox.page'));

const ClientUsers = lazy(() => import('../pages/settings/ClientUsers.page'));
const Candidates = lazy(() => import('../pages/Candidates.page'));
const ManageDashboard = lazy(
  () => import('../pages/settings/ManageDashboard.page')
);
const RecentActivity = lazy(() => import('../pages/RecentActivity.page'));

const WorkspaceRoutes = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    {
      path: 'dashboard',
      element: <DashboardPage />,
    },
    {
      path: 'postings',
      element: (
        <SuspenseView>
          <RecruiterInboxPage />
        </SuspenseView>
      ),
    },
    {
      path: 'postings/:id',
      element: (
        <SuspenseView>
          <JobPostingPage />
        </SuspenseView>
      ),
    },
    {
      path: 'postings/create',
      element: (
        <SuspenseView>
          <AddJob />
        </SuspenseView>
      ),
    },
    {
      path: 'postings/job',
      element: (
        <SuspenseView>
          <EditJobPage />
        </SuspenseView>
      ),
    },
    {
      path: 'postings/add-candidate',
      element: <AddCandidate />,
    },
    {
      path: 'applicants',
      element: (
        <SuspenseView>
          <Candidates />
        </SuspenseView>
      ),
    },
    {
      path: 'applicants/:jhash',
      element: <JobApplicants />,
    },
    {
      path: 'applicant',
      element: <CandidateDetailsView />,
    },
    // {
    //   path: 'settings',
    //   element: (
    //     <SuspenseView>
    //       <SettingsPage />
    //     </SuspenseView>
    //   ),
    // },
    // {
    //   path: 'settings/invite',
    //   element: <Invite />,
    // },
    // {
    //   path: 'settings/invite-client-users',
    //   element: (
    //     <SuspenseView>
    //       <InviteClientUsersPage />
    //     </SuspenseView>
    //   ),
    // },
    // {
    //   path: 'settings/users',
    //   element: <Users />,
    // },
    // {
    //   path: 'settings/client-users',
    //   element: (
    //     <SuspenseView>
    //       <ClientUsers />
    //     </SuspenseView>
    //   ),
    // },
    // {
    //   path: 'settings/email',
    //   element: <EmailComposer />,
    // },
    // {
    //   path: 'settings/client-list',
    //   element: (
    //     <SuspenseView>
    //       <Clients />
    //     </SuspenseView>
    //   ),
    // },
    // {
    //   path: 'settings/workspace-functional-area',
    //   element: (
    //     <SuspenseView>
    //       <WorkspaceFunctionalAreaPage />
    //     </SuspenseView>
    //   ),
    // },
    // {
    //   path: 'settings/manage-dashboard',
    //   element: (
    //     <SuspenseView>
    //       <ManageDashboard />
    //     </SuspenseView>
    //   ),
    // },
    {
      path: 'calendar',
      element: <EventCalendar />,
    },
    {
      path: 'notifications',
      element: (
        <SuspenseView>
          <NotificationPage />
        </SuspenseView>
      ),
    },
    {
      path: 'candidates/talent-pool',
      element: (
        <SuspenseView>
          <TalentPoolPage />
        </SuspenseView>
      ),
    },
    {
      path: 'dashboard/recruiter-report',
      element: (
        <SuspenseView>
          <Reports />
        </SuspenseView>
      ),
    },
    {
      path: 'recent-activities',
      element: (
        <SuspenseView>
          <RecentActivity />
        </SuspenseView>
      ),
    },
  ],
};

export default WorkspaceRoutes;
