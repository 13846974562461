/**
 * Description: HireXL/React app bootloader
 * Author: Ankur Sharma<ankur.sharma@technocube.in>
 */
import './wdyr';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Notifications } from 'react-push-notification';

import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import FallbackUI from './components/fallback-UI/FallbackPage.react';
import ParseableTransporter from './logger/parseable-transport';

import './styles/style.scss';

Sentry.init({
  dsn: 'https://5e42ee190d5b43a39eac9354a905df90@o284214.ingest.sentry.io/1519301',
  environment: 'production',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const handleError = (error: any) => {
  ParseableTransporter.log(error);
};

root.render(
  <Provider store={store}>
    <Notifications />
    <HashRouter>
      <Sentry.ErrorBoundary fallback={<FallbackUI />} onError={handleError}>
        <App />
      </Sentry.ErrorBoundary>
    </HashRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
