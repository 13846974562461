import { axiosInstance } from '../../utils/axios.instance';

export class SettingAPI {
  static async getProviders(id: number) {
    return axiosInstance({
      method: 'GET',
      url: `/api/v1/workspace-settings/email-providers?workspace=${id}`,
    });
  }

  static async setUpEmailProviderConfig(data: Record<string, string>) {
    return axiosInstance({
      method: 'POST',
      url: '/api/v1/workspace-settings/configure-email-provider',
      data,
    });
  }

  static async updateEmailProviderConfig(data: Record<string, string>) {
    return axiosInstance({
      method: 'PUT',
      url: '/api/v1/workspace-settings/configure-email-provider',
      data,
    });
  }

  static async emailProviderEnableFlag(id: number, data: { enabled: boolean }) {
    return axiosInstance({
      method: 'PUT',
      url: `/api/v1/workspace-settings/email-providers/${id}`,
      data,
    });
  }

  static async deleteEmailProvider(provider: number) {
    return axiosInstance({
      method: 'PUT',
      url: `/api/v1/workspace-settings/configure-email-provider/${provider}`,
    });
  }
}
