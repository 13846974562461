import { useEffect, useState, ChangeEvent } from 'react';
import {
  Grid,
  Skeleton,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import _, { isArray } from 'lodash';
import dayjs from 'dayjs';
import randomString from 'randomstring';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import {
  fetchEvalTemplate,
  saveFeedback,
  getFeedbacks,
  resetEvaluvationSubmit,
} from '../../redux/features/jobs/evaluation-template.slice';
import { getHiringWorkflow } from '../../redux/features/jobs/hiringWorkflow.slice';
import Header from './Header.react';
import Resume from './Resume.react';
import RecruiterFeedback from './RecruiterFeedback.react';
import RecruiterFeedbackForm from './RecruiterFeedbackForm.react';
import AddEventModal from '../event-calendar/AddEventModal.react';
import ApplicationTimeline from '../application-timeline';
import CommentView from '../comment-view';
import {
  addTaskLog,
  taskShareProfile,
  applicationTimeline,
} from '../../redux/services/hiring-workflow.service';
import CalendarAPI from '../../redux/services/calendar.service';
import { IEvent } from '../../types/calendar';
import { sendJobDescriptionEmail } from '../../redux/services/jobs';
import JobAPI from '../../redux/services/job.service';
import {
  fetchComments,
  addComment,
  updateComment,
  setComments,
} from '../../redux/features/comments/comment.slice';
import {
  candidateById,
  updateApplicant,
} from '../../redux/features/applications/applicationSlice';

import useLocalStorage from '../../hooks/use-localstorage';
import { ROOT_URL } from '../../utils/constants';

interface Props {
  candidateId?: number | null;
  isShowTimeline?: boolean;
}

export default (props: Props) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const { isShowTimeline = true } = props;

  const { candidate } = useAppSelector((state) => state.application);
  const candidateId = params.get('candidateId') || props.candidateId;
  const { token = '' } = useAppSelector((state) => state.app);
  const { hiringWorkflow } = useAppSelector((state) => state.hiringWorkflow);
  const { commentList } = useAppSelector((state) => state.comment);
  const { evalTemplate, evaluations } = useAppSelector(
    (state: any) => state.evaluation
  );
  const [stageTimeline, setStageTimeline] = useState<any>([]);
  const [stakeholder, setStakeholder] = useState<any>([]);
  const [timelineLogs, setTimelineLogs] = useState<any>([]);
  const [openEventModal, setOpenEventModal] = useState<boolean>(false);
  const [isTaskLogUpdating, setTaskLogUpdating] = useState<boolean>(false);
  const [clientFeedbackActions, setCientFeedbackShared] = useState<any>(null);
  const [leadFeedbackActions, setTLFeedbackShared] = useState<any>(null);
  const [user] = useLocalStorage('user', null);

  useEffect(() => {
    if (candidateId) {
      dispatch(candidateById(Number(candidateId)));
    }
  }, [candidateId]);

  useEffect(() => {
    return () => {
      dispatch(resetEvaluvationSubmit());
    };
  }, []);

  const getTimelineLogs = (applicationId: number) => {
    applicationTimeline(applicationId)
      .then(({ data }) => {
        const TLFeedback = ['TL_APPROVED_PROFILE', 'TL_DECLINED_PROFILE'];
        const CLFeedback = [
          'CLIENT_APPROVED_PROFILE',
          'CLIENT_DECLINED_PROFILE',
        ];
        const leadActionsDone = _.filter(data, (log: any) =>
          TLFeedback.includes(log.task.slug)
        );

        const CLActionsDone = _.filter(data, (log: any) =>
          CLFeedback.includes(log.task.slug)
        );
        const leadActions = _.find(
          data,
          (log: any) => log.task.slug === 'PROFILE_SHARED_TO_TL'
        );
        const clActions = _.find(
          data,
          (log: any) => log.task.slug === 'PROFILE_SHARED_TO_CLIENT'
        );
        if (!leadActionsDone.length && leadActions) {
          setTLFeedbackShared(leadActions.actions);
        } else {
          setTLFeedbackShared(null);
        }
        if (!CLActionsDone.length && clActions) {
          setCientFeedbackShared(clActions.actions);
        } else {
          setCientFeedbackShared(null);
        }
        setTimelineLogs(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobStakeholder = (jobId: number) => {
    JobAPI.stakeholder(jobId)
      .then((response) => {
        setStakeholder(response.data);
      })
      .catch((error) => {
        setStakeholder([]);
      });
  };

  useEffect(() => {
    dispatch(getHiringWorkflow(Number(candidate.details.jobId)));
    dispatch(fetchEvalTemplate(Number(candidate.details.jobId)));
    getJobStakeholder(Number(candidate.details.jobId));
  }, [candidate.details.jobId]);

  const sessionEmail = user?.email || '';
  const domain = sessionEmail.substring(sessionEmail.lastIndexOf('@') + 1);

  useEffect(() => {
    dispatch(getFeedbacks(Number(candidateId)));
    getTimelineLogs(Number(candidateId));
    dispatch(fetchComments(Number(candidateId)));
  }, [candidateId, user]);

  useEffect(() => {
    if (hiringWorkflow.data.length) {
      let status = 'Done';
      const pipelineStages: any = hiringWorkflow.data.map((stage: any) => {
        if (stage.id == candidate.details.workFlowId) {
          status = 'Upcoming';
        }
        return {
          diaplayName: stage.stageName,
          status: stage.id == candidate.details.workFlowId ? 'Active' : status,
        };
      });
      setStageTimeline(pipelineStages);
    }
  }, [hiringWorkflow.data]);

  useEffect(() => {
    const eventSource = new EventSource(
      `${ROOT_URL}/api/v1/comments/sse/${candidate.details.id}?userId=${user.id}`
    );

    eventSource.onmessage = (event) => {
      const newData = JSON.parse(event.data);
      dispatch(setComments(newData));
    };

    return () => {
      eventSource.close();
    };
  }, [dispatch, candidate.details.id]);

  const {
    name = 'N/A',
    email = 'N/A',
    mobile = 'N/A',
    job,
    s3Url = '',
    remark,
    createdAt,
    jobId = '',
    id = '',
  } = candidate.details;

  const handleTaskSubmit = (data: any) => {
    const { id, jobId }: any = candidate.details;
    setTaskLogUpdating(true);
    addTaskLog({ ...data, jobId, applicationId: id })
      .then((response) => {
        setTaskLogUpdating(false);
        getTimelineLogs(id);
      })
      .catch((err) => {
        console.log(err);
        setTaskLogUpdating(false);
      });
  };

  const handleTaskType = (taskType: string, assignedToId?: Array<number>) => {
    const { id, jobId }: any = candidate.details;
    setTaskLogUpdating(true);
    if (taskType == 'SHARE_JD') {
      sendJobDescriptionEmail({
        recipients: [email],
        subject: 'Job Description',
        message: '<p>Hi</p> <p>Please find job description</p>',
        jobId: jobId,
      })
        .then((res) => {
          getTimelineLogs(id);
          setTaskLogUpdating(false);
        })
        .catch((err) => {
          setTaskLogUpdating(false);
        });
    } else {
      taskShareProfile({ taskType, jobId, applicationId: id, assignedToId })
        .then((response) => {
          getTimelineLogs(id);
          setTaskLogUpdating(false);
        })
        .catch((err) => {
          console.log(err);
          setTaskLogUpdating(false);
        });
    }
  };

  const handleCloseEventModal = () => {
    setOpenEventModal(false);
  };

  const handleAddEvent = (event: any) => {
    const eventRequest: IEvent = {
      summary: event.title,
      description: event.description,
      start: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        ).toISOString(),
        timeZone: 'utc',
      },
      end: {
        dateTime: dayjs(
          `${event.date} ${event.startTime}`,
          'YYYY-MM-DD hh:mm:A'
        )
          .add(event.duration, 'minutes')
          .toISOString(),
        timeZone: 'utc',
      },
      attendees: event.attendees.map((user: any) => ({ email: user })),
    };
    if (event.googleConfrence) {
      eventRequest['conferenceData'] = {
        createRequest: {
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
          requestId: randomString.generate(10),
        },
      };
    }
    CalendarAPI.addEvent(eventRequest)
      .then((response) => {
        setOpenEventModal(false);
      })
      .catch((error) => {
        console.log('err', error);
      });
  };

  const handleOpenEventModal = () => setOpenEventModal(true);

  const onSubmitFeedback = (values: any) => {
    const { id, jobId }: any = candidate.details;
    const feedback: any = [];
    Object.keys(values).map((key: any) => {
      const data = {
        label: key,
        value: values[key],
        jobId,
        applicationId: id,
      };

      feedback.push(data);
    });

    dispatch(saveFeedback(feedback));
  };

  const handleCommentSubmit = (
    mode: string,
    inputText: string,
    commentId?: number | null,
    commentParentId?: number | null
  ) => {
    const payload = {
      postId: Number(candidate.details.id),
      commentText: inputText,
      parentCommentID: commentParentId,
    };
    if (mode === 'EDIT') {
      dispatch(updateComment({ commentId, data: payload }));
    } else {
      dispatch(addComment(payload));
    }
  };

  const handleUpdateCV = (event: ChangeEvent<HTMLInputElement> | undefined) => {
    //@ts-ignore
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('id', `${candidate.details.id}`);
      formData.append('file', file);
      dispatch(updateApplicant(formData));
    }
  };

  const handleUpdateCandidateInfo = (data: any) => {
    return new Promise((resolve, reject) => {
      dispatch(updateApplicant({ ...data, id: candidate.details.id }))
        .then((result) => {
          if (updateApplicant.rejected.match(result)) {
            const message = result.payload || 'Something went wrong!';
            reject(message);
          }
          resolve('');
        })
        .catch((error) => {
          console.log('error', error);
          reject('Something went wrong!');
        });
    });
  };

  return (
    <Grid
      container
      p={2}
      spacing={2}
      data-component-name={`candidateDetails-${candidateId}`}
    >
      <AddEventModal
        open={openEventModal}
        handleClose={handleCloseEventModal}
        onAddEvent={handleAddEvent}
        event={{
          extendedProps: {
            raw: {
              attendees: [{ email }],
            },
          },
        }}
      />
      <Grid item xs={12} md={isShowTimeline ? 8 : 12}>
        {candidate.loading == 'succeeded' ? (
          <Header
            name={name}
            email={email}
            mobile={mobile}
            job={job}
            remark={remark}
            createdAt={createdAt}
            stageProgress={stageTimeline}
            onUpdate={handleUpdateCandidateInfo}
          />
        ) : (
          <CircularProgress size={24} />
        )}
        <RecruiterFeedback
          onTaskSubmit={handleTaskSubmit}
          onTaskTypeSubmit={handleTaskType}
          loading={isTaskLogUpdating}
          applicationId={Number(candidate.details.id)}
          jobId={Number(candidate.details.jobId)}
          taskTLFeedback={leadFeedbackActions}
          taskCLFeedback={clientFeedbackActions}
          onOpenEventModal={handleOpenEventModal}
          stakeholders={stakeholder}
          hiringWorkflow={
            isArray(hiringWorkflow?.data) ? hiringWorkflow?.data : []
          }
        />
        {evaluations.loading === 'pending' ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={60}
            sx={{ marginY: 2 }}
          />
        ) : (
          <RecruiterFeedbackForm
            applicationId={Number(candidate.details.id)}
            jobId={Number(candidate.details.jobId)}
            onSubmitFeedback={onSubmitFeedback}
            evaluationTemaplate={evalTemplate.data}
            feedbacks={evaluations.data}
          />
        )}
        <Resume
          cvUrl={s3Url}
          token={token}
          onChangeCV={handleUpdateCV}
          loading={candidate.loading == 'pending'}
        />
        <Box py={1} />

        {user && domain == 'savannahr.com' ? (
          <CommentView
            comments={commentList.data}
            user={user}
            stakeholder={stakeholder}
            onSubmit={handleCommentSubmit}
            isSubmitting={commentList.loading == 'pending'}
          />
        ) : (
          <></>
        )}
      </Grid>
      {isShowTimeline && (
        <Grid item xs={12} md={4}>
          <ApplicationTimeline timeline={timelineLogs} />
        </Grid>
      )}
    </Grid>
  );
};
