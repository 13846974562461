import ListAltIcon from '@mui/icons-material/ListAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import WorkIcon from '@mui/icons-material/Work';
import StorageIcon from '@mui/icons-material/Storage';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import GroupsIcon from '@mui/icons-material/Groups';

const icons = {
  ListAltIcon,
  PlaylistAddIcon,
  PeopleAltIcon,
  MoveToInboxIcon,
  WorkIcon,
  CreateNewFolderIcon,
  StorageIcon,
  GroupsIcon,
};

export default {
  id: 'candidates',
  title: 'Candidates',
  type: 'group',
  children: [
    {
      id: 'talent-pool',
      title: 'Talent Pool',
      type: 'item',
      url: 'candidates/talent-pool',
      icon: icons.GroupsIcon,
      breadcrumbs: false,
    },
  ],
};
